import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { MainContext } from "context/context"

import SEO from "components/seo"
import FlexibleContent from "components/flexibleContent"

export default ({ data }) => {
	const {
		databaseId,
		slug,
		excerpt,
		flexibleContent,
		featuredImage
	} = data.siteFields.page

	const {
		pageTitle,
		extraCopy,
		meta
	} = flexibleContent

    const {
		setCurrentPage
	} = useContext(MainContext)
	
	useEffect(() => {
		if(extraCopy){
			setCurrentPage(extraCopy)
		}

	}, [extraCopy])

	return (
		<>
			<SEO 
				title={pageTitle} 
				description={(meta && meta.description) ? meta.description : excerpt} 
				metaTitle={(meta && meta.title) ? meta.title : undefined}
				image={featuredImage ? featuredImage.mediaItemUrl : ''}
			/>
			<FlexibleContent
				id={databaseId}
				content={flexibleContent}
				slug={slug}
			/>
		</>
	)
}

export const query = graphql`
	query($databaseId: ID!) {
		siteFields {
			page(id: $databaseId, idType: DATABASE_ID) {
				databaseId
				title
				slug
				date
				content(format: RENDERED)
				featuredImage {
					altText
					title(format: RENDERED)
					mediaItemUrl
					slug
				}
				flexibleContent {
					... FlexibleContentFragment
				}
			}
		}
	}
`
